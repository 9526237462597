import { mq } from "@app/design-lib/theme"
import React from "react"

export const TenantHeaderContent: React.FC = () => {
  const onClickTenantLogo = () => {
    window.location.href = "https://www.unicreditsubitocasa.it/"
  }

  return (
    <>
      <a href="#" onClick={onClickTenantLogo} style={{ display: "flex", alignContent: "center" }}>
        <img
          src="/flow/icons/tenant/usc-logo.svg"
          alt="unicredit subito casa società di intermediazione immobiliare"
          css={{ width: 180, [mq.large]: { width: 260 } }}
        />
      </a>
      <img
        src="/flow/icons/tenant/powered-by-casavo.svg"
        alt="powered by Casavo"
        css={{ width: 72, [mq.large]: { width: 108 } }}
      />
    </>
  )
}
